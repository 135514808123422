import React, { useContext, useEffect, useState } from 'react'
import { graphql, Link, PageProps } from 'gatsby'
import { Helmet } from 'react-helmet'
import get from 'lodash/get'
import Img from 'gatsby-image'
import Layout, { getFirebaseToken, getUserDocument, UserContext, UserContextType, withLayout } from '../components/layout'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';


import { navigate } from '@reach/router';
import styles from "./course.module.css"
import { Breadcrumbs, Button, Checkbox, CircularProgress, List, ListItem, ListItemSecondaryAction, ListItemText } from '@material-ui/core'
import firebase from 'firebase'
import { resolveTypeReferenceDirective } from 'typescript'
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const CoursePage = (props: PageProps<GatsbyTypes.CourseBySlugQuery>) => {

  let course = props.data.contentfulCourse;
  const [doesUserHaveCourse, setDoesUserHaveCourse] = useState<boolean>(null)
  const [courseProgress, setCourseProgress] = useState({})
  const [inCart, setInCart] = useState(false)

  const userContext = useContext<UserContextType>(UserContext);

  useEffect(() => {

    async function getUserStatus() {
      let idToken = await getFirebaseToken();
      if (userContext.loggedIn) {
        let hasCourse = await checkIfUserHasCourse(idToken, course?.slug, setDoesUserHaveCourse);
        // setDoesUserHaveCourse(hasCourse);
        if (hasCourse) {
          let progress = await getCourseProgress(course?.slug as string);
          setCourseProgress(progress);
        }

        let cart = cookies.get("cart")
        if (cart !== undefined) {
          console.log(cart)
          if(cart[course?.slug] == true){
            setInCart(true);
          }
        }

      } else if (userContext.loggedIn != null) {
        setDoesUserHaveCourse(false);
      }
    }

    getUserStatus();

    return () => {
    }
  }, [userContext?.loggedIn])

  return (
    <>
      <div className={styles.container}>

        <div className={styles.headerContainer}>
          <h1 className={styles.header}>{props.data.contentfulCourse?.title}</h1>


          {doesUserHaveCourse == null ? <CircularProgress /> :
            <PurchasedButton setInCart={setInCart} inCart={inCart} userContext={userContext} course={course as GatsbyTypes.ContentfulCourse} doesUserHaveCourse={doesUserHaveCourse} />
          }

        </div>

        <Breadcrumbs aria-label="breadcrumb">
          <Link color="inherit" to={`/courses`}>
            Courses
          </Link>
          <Link color="inherit" to={`/${course?.slug}`}>
            {course?.title}
          </Link>
        </Breadcrumbs>
        <hr></hr>

        {//@ts-ignore 
          documentToReactComponents(props.data.contentfulCourse?.description?.json)
        }

        <div className={styles.lessonsWrapper}>
          <List style={{ listStyle: "none" }}>
            {props.data.contentfulCourse?.lessons?.map(lesson => <LessonListing
              // key={lesson?.slug}
              lesson={lesson}
              course={course}
              loggedIn={userContext.loggedIn}
              completed={courseProgress[lesson?.slug as string]}
              key={lesson?.slug + "" + courseProgress[lesson?.slug as string]}
              userHasCourse={doesUserHaveCourse}
            />)}
          </List>
        </div>

      </div>

    </>
  )
}

export default withLayout(CoursePage)




function PurchasedButton({ userContext, course, doesUserHaveCourse, inCart, setInCart }: { userContext: UserContextType, course: GatsbyTypes.ContentfulCourse, doesUserHaveCourse: boolean }) {
  return (
    <div>
      {!userContext?.loggedIn ?
        // Not logged in
        <Button className={styles.buyButton} variant="contained" color="primary" onClick={() => { navigate("/account?redirectOnSuccess=" + course?.slug) }}> Login to Buy/View Course</Button>
        :

        //Logged in, check if they bought already
        <>
          {!doesUserHaveCourse ?
          (!inCart? 
            <Button className={styles.buyButton} variant="contained" color="primary" onClick={() => { addToCart(course?.slug, setInCart) }}> Add to cart </Button> 
            :
            <Button className={styles.buyButton} variant="contained" color="secondary" onClick={() => { navigate("/cart")}}>Checkout</Button> 
          ):
            // <Button className={styles.buyButton} variant="contained" color="primary" onClick={() => { buyCourse(course?.slug) }}>Buy Course</Button> :
            <Button className={styles.purchasedButton} variant="contained" onClick={(e) => { e.preventDefault() }}> Purchased</Button>}
        </>}
    </div>
  )
}

function LessonListing({ lesson, course, loggedIn, completed, userHasCourse }) {
  console.log("does user have course", userHasCourse)
  return (
    // <Link to={lesson?.slug}>
    <ListItem button key={lesson.title} className={styles.listBackground} color="primary" onClick={() => { navigate(`/${course.slug}/${lesson?.slug}`) }}>
      <ListItemText primary={lesson.title} />
      <ListItemSecondaryAction>
        {loggedIn ?
          (completed == undefined ? (userHasCourse === false ? null : <CircularProgress />) : <Checkbox
            edge="end"
            onChange={(e) => { setLessonCompleted(e.target.checked, lesson.slug, course?.slug); }}
            defaultChecked={completed}
          />) : null}
      </ListItemSecondaryAction>
    </ListItem>

    // </Link>    
  )

}

async function setLessonCompleted(value, lessonSlug: string, courseSlug: string) {
  console.log("value is", value);



  let idToken = await getFirebaseToken();
  let res = await fetch(`/.netlify/functions/setLessonCompleted?courseSlug=${courseSlug}&lessonSlug=${lessonSlug}&value=${value}&id=${idToken}`);
  console.log(res);
  if (res.status != 200) {
    console.log("Error setting lesson completed");
  }

}


async function getCourseProgress(courseSlug: string) {
  let userDocument = await getUserDocument();
  let courseProgress = userDocument?.courseProgress;
  if (courseProgress) {
    return courseProgress[courseSlug];
  }
  return {};
}

async function checkIfUserHasCourse(idToken, courseSlug, setDoesUserHaveCourse) {
  let r = await fetch(`/.netlify/functions/doesUserHaveCourse?slug=${courseSlug}&id=${idToken}`);
  let res = await r.json()
  console.log(res)
  if (r.status == 200) {
    setDoesUserHaveCourse(true);
    return true;
  }
  else {
    setDoesUserHaveCourse(false);
    return false;
  }
}

async function buyCourse(courseSlug) {
  let email = firebase.auth().currentUser?.email
  fetch(`/.netlify/functions/buyCourse?slug=${courseSlug}&email=${email}`).then(res => { console.log(res); return (res.json()) }).then(res => {
    console.log(res)
    if (res.status == 200 && res.url) {
      navigate(res.url);
    }
    else {
      throw "Failed to fetch from backend server."
    }
  }).catch(err => {
    // console.log(err);
    alert("Something went wrong... Please contact us at help@leanqualityacademy.com if you continue experiencing this issue. Error:" + err.toString());
    throw err
  })

}

async function addToCart(courseSlug, setInCart) {
  let cart = cookies.get("cart")
  if (cart == undefined) {
    cart = {}
  }
  cart[courseSlug] = true
  cookies.set('cart', cart, { path: '/' });
  setInCart(true);
}

export const pageQuery = graphql`
query CourseBySlug($slug: String!) {
  contentfulCourse(slug: { eq: $slug }) {
    title
    id
    slug
    description{
      json
    }
    lessons{
      title
      slug
    }
  }
}
`
